<template>
<div>
  <Navlid :list="navList" name="费用详情"></Navlid>
  <div class="datapreview">
    <el-form :inline="true" style="margin-top:10px">
      <el-form-item>
				<el-date-picker v-model="params.bill_time" format="YYYY-MM" value-format="YYYY-MM" type="month" placeholder="请选择年月"/>
      </el-form-item>
      <el-form-item>
        <el-select v-model="params.bill_status">
					<el-option :value="-1" label="全部"></el-option>
          <el-option :value="0" label="未缴纳"></el-option>
          <el-option :value="10" label="已缴纳"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <myButton title="搜索" @click="getList">
          <template v-slot:preImage>
            <img src="@/unit/img/search.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <myButton title="导出" @click="exportDataEvent">
          <template v-slot:preImage>
            <img src="@/unit/img/down.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <div class="ysall">
          <span>应收总金额:</span>
          <span>{{dataDetail && dataDetail.total_price || 0}}</span>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="ysall">
          <span>已收总金额:</span>
          <span>{{dataDetail && dataDetail.pay_price || 0}}</span>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="ysall">
          <span>未收总金额:</span>
          <span>{{dataDetail && dataDetail.not_pay_price || 0}}</span>
        </div>
      </el-form-item>
    </el-form>
    <vxe-table ref="xTable1" :row-config="{ isHover: true }" header-cell-class-name="table-header" cell-class-name="table-cell" :show-overflow="showOverflow1" :data="tableData">
      <vxe-column field="bill_name" title="房屋"></vxe-column>
      <vxe-column field="merchants_name" title="业主姓名"></vxe-column>
      <vxe-column field="property_time" title="账期"></vxe-column>
      <vxe-column field="actual_area" title="面积"></vxe-column>
      <vxe-column field="key_price" title="单价"></vxe-column>
      <vxe-column field="key_pay_price" title="费用"></vxe-column>
      <vxe-column field="bill_status" title="缴费状态">
      	<template #default="{ row }">
					{{row.bill_status == 10 ? '已缴费' : '未缴费'}}
				</template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :current-page="params.pageNum" :page-size="params.pageSize" :total="total" :layouts="['PrevPage', 'JumpNumber', 'NextPage', , 'Total']" @page-change="getList">
    </vxe-pager>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      showOverflow1: false,
      navList: [{
          title: '数据总览',
          url: '/index/community/dataView'
        },
        {
          title: '费用详情'
        }
      ],
      params: {
				pageNum: 1,
				pageSize: 10,
				market_id: '',
				bill_status: -1,
				bill_time: ''
      },
      tableData: [],
			total: 0,
      page: 1,
			dataDetail: null
    }
  },
	created() {
	  this.params.market_id = this.$route.query.id;
		this.getList();
		this.getTotal();
	},
  methods: {
		getTotal() {
      this.$request.HttpPost('/community/property/index-market-total', {
        market_id: this.params.market_id
      }).then(res => {
        this.dataDetail = res.data;
      });
    },
    exportDataEvent() {
      this.$refs.xTable1.exportData({
        type: 'csv'
      })
    },
    getList(e = { currentPage: 1 }) {
			this.params.pageNum = e.currentPage;
			this.$request.HttpPost('/community/property/index-market-list', this.params).then(res => {
				this.tableData = res.data.list || [];
				this.total = res.data.total;
			});
    },
    goUrl() {
      this.$router.push('/index/dataView/dataDetail')
    }
  }
}
</script>
<style lang="scss">
.ysall {
    display: inline-block;
    width: 160px;
    height: 32px;
    background: #02ce80;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 32px;
    span {
        &:nth-child(2) {
            font-weight: bold;
            margin-left: 12px;
        }
    }
}
</style>
